import React from "react"
import { Helmet } from "react-helmet"
import HubspotForm from "react-hubspot-form"
import Layout from "../components/layout"
import Video from "../components/video"

const RegionsPage = () => {
  // const node = data.wpgraphql.pageBy;

  return (
    <Layout>
      <Helmet>
        <meta name="robots" content="noindex" />
        <body className="page-regions" />
      </Helmet>

      <header className="simple-site-header">
        <div className="container">
          <div className="simple-site-header-branding">
            <a title="home" href="/" className="simple-site-header-logo">
              <img
                src="https://gbdevpress.enerbank.com/wp-content/uploads/2021/12/EB-Regions_email-header_v1_BWP.svg"
                alt="Regions + EnerBank logo"
              />
            </a>
          </div>

          <div className="simple-site-header-navigation">
            <a title="enerbank.com" href="https://enerbank.com/">
              enerbank.com
            </a>
          </div>
        </div>
      </header>

      <section
        className="banner-area"
        id="regions-page-banner-section"
        style={{
          backgroundImage:
            "url(" +
            "https://gbdevpress.enerbank.com/wp-content/themes/enerbank/img/hero-img.jpg" +
            ")",
        }}
      >
        <div className="row align-items-center relative banner-area-text-container">
          <div className="container text-center">
            <h1 className="text-white" style={{ padding: "80 0" }}>
              Refer Contractors to EnerBank!
            </h1>
          </div>
        </div>
      </section>

      <section className="regions-page-video-form-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <p>
                <strong>
                  Follow these steps to refer your customers (home improvement
                  contractors):{" "}
                </strong>
              </p>
              <ol className="list-numeric">
                <li>Complete the online form on this page</li>
                <li>
                  Download the application using{" "}
                  <a
                    href="/4057-Express-Loan-T2L-Dealer-Application.pdf"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    this link{" "}
                  </a>
                </li>
                <li>
                  Fill out the application with your customer, and have them
                  sign
                </li>
                <li>
                  Review the required documentation needed to apply{" "}
                  <a
                    href="/EB Required Documents V2.pdf"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    here
                  </a>
                </li>
                <li>
                  Upload the completed application and required documentation{" "}
                  <a
                    href="https://www.mysecuredock.com/m/upload?list=65719f02-4fe0-454f-bd7d-a505d4b92c48"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    here
                  </a>
                </li>
                <li>
                  Someone from EnerBank will follow up with your customer to get
                  started
                </li>
              </ol>
              &nbsp;
              <p className="mb-4">
                For 20 years. EnerBank has been providing contractors around the
                country with the ability to offer homeowners payment options for
                their home renovation projects.
              </p>
              <div className="mb-5">
                <div className="embed-container">
                  <Video
                    videoSrcURL={
                      "https://player.vimeo.com/video/657870431?h=c1cafb924d"
                    }
                    allow="autoplay"
                    videoTitle="Consumer Banking Summit"
                    videoWidth="100%"
                    videoHeight="500"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="regions-page-form-container">
                {/* <div dangerouslySetInnerHTML={{
                  __html: `
                  <style>.hs-form .hs-input{ width: 100%!important; } body .hs-form fieldset.form-columns-1 .hs-form-field{ padding-right: 0; }.hs-form .hs_submit{margin-bottom: 1.5rem;}</style>
                  <!--[if lte IE 8]>
                  <script charset="utf-8" type="text/javascript" src="https://js.hsforms.net/forms/v2-legacy.js"></script>
                  <![endif]-->
                  <script charset="utf-8" type="text/javascript" src="https://js.hsforms.net/forms/v2.js"></script>
                  <script>
                  hbspt.forms.create({
                  region: "na1",
                  portalId: "381510",
                  formId: "a4183037-ea84-4250-852c-1cc46d9bbdc8"
                  });
                  </script>
                  `
                }} /> */}
                <HubspotForm
                  portalId="381510"
                  formId="a4183037-ea84-4250-852c-1cc46d9bbdc8"
                  onSubmit={() => this.handleFormSubmit}
                  onReady={form => console.log("Form ready!")}
                  loading={<div>Loading...</div>}
                />
                <p className="font-weight-bold mb-0 text-center">
                  Any questions? Call{" "}
                  <a href="tel:888.704.5376">888.704.5376</a>
                </p>
              </div>
              <p>
                <a
                  className="font-weight-normal text-brand-blue"
                  target="_blank"
                  href="/EnerBank_FactSheet_EligibleImprovementList_WEB-v1.pdf"
                  rel="noreferrer noopener"
                >
                  <u>Click Here</u>
                </a>{" "}
                to Download Program Details
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="regions-page-who-should-refer-section">
        <div className="container">
          <h2 className="text-center text-brand-blue mb-5">
            Who Should I Refer?
          </h2>

          <ul className="who-should-refer-list">
            <li>
              <img
                src="https://gbdevpress.enerbank.com/wp-content/uploads/2021/12/3yrs-in-business.png"
                alt="years in business"
              />
              <p>
                3+ years in business
                <br />
                (5+ preferred)*
              </p>
            </li>
            <li>
              <img
                src="https://gbdevpress.enerbank.com/wp-content/uploads/2021/12/500k-annual.png"
                alt="annual residential sales"
              />
              <p>$500,000+ annual residential sales*</p>
            </li>
            <li>
              <img
                src="https://gbdevpress.enerbank.com/wp-content/uploads/2021/12/5k-minimum.png"
                alt="minimum job size"
              />
              <p>$5,000 minimum average job size</p>
            </li>
            <li>
              <img
                src="https://gbdevpress.enerbank.com/wp-content/uploads/2021/12/Asset-9@4x.png"
                alt="project types"
              />
              <p>Sell one of 120 different project types*</p>
            </li>
            <li>
              <img
                src="https://gbdevpress.enerbank.com/wp-content/uploads/2021/12/stisfactory-BCR.png"
                alt="credit report"
              />
              <p>Satisfactory business credit report</p>
            </li>
            <li>
              <img
                src="https://gbdevpress.enerbank.com/wp-content/uploads/2021/12/satisfactory-BBB-report.png"
                alt="Business Bureau report"
              />
              <p>Satisfactory Better Business Bureau report</p>
            </li>
            <li>
              <img
                src="https://gbdevpress.enerbank.com/wp-content/uploads/2021/12/licensed-insured.png"
                alt="insured"
              />
              <p>Licensed & insured</p>
            </li>
            <li>
              <img
                src="https://gbdevpress.enerbank.com/wp-content/uploads/2021/12/120-project-types.png"
                alt="project types"
              />
              <p>Pass a financial review</p>
            </li>
          </ul>

          <p
            className="text-center font-weight-normal text-brand-blue"
            style={{ fontSize: 20 }}
          >
            <a
              title="Eligible Improvement List"
              target="_blank"
              rel="noreferrer noopener"
              href="https://gbdevpress.enerbank.com/wp-content/uploads/2021/12/Eligible-Improvement-List-Dec-2021.pdf"
            >
              <u>*120 Home Improvement Project Eligible for Financing</u>
            </a>
          </p>
        </div>
      </section>

      <section className="regions-page-how-it-works-section">
        <div className="container">
          <h2 className="text-center text-brand-blue mb-5">How It Works</h2>

          <img
            src="https://gbdevpress.enerbank.com/wp-content/uploads/2021/12/how-it-works-graphic.png"
            alt="process"
          />
        </div>
      </section>

      <section className="regions-page-contractor-benefits-section">
        <div className="container">
          <h2 className="text-center text-white mb-5">
            How do Contractors Benefit from Offering Payment Options?
          </h2>

          <ul className="contractor-benefits-list">
            <li>
              <img
                src="https://gbdevpress.enerbank.com/wp-content/uploads/2021/12/Improve-Cash-Flow.png"
                alt="Improved Cash Flow"
              />
              <h3>Improved Cash Flow</h3>
              <p>
                Funds will be deposited in the contractor&apos;s account
                typically within 24 hours of a request
              </p>
            </li>
            <li>
              <img
                src="https://gbdevpress.enerbank.com/wp-content/uploads/2021/12/Increase-Leads.png"
                alt="increase leads"
              />
              <h3>Increase Leads</h3>
              <p>
                Contractors can see a 50% increase in their leads when offering
                a choice of payment options
              </p>
            </li>
            <li>
              <img
                src="https://gbdevpress.enerbank.com/wp-content/uploads/2021/12/Grow-Average-Project-Size.png"
                alt="grow project size"
              />
              <h3>Grow Average Project Size</h3>
              <p>
                Homeowners who finance their projects spend 44% more on average
              </p>
            </li>
          </ul>
        </div>
      </section>

      <section className="regions-page-loan-options-match-section">
        <div className="container">
          <h2 className="text-center text-brand-blue mb-5">
            Loan Options that Match the Needs of Home Improvement Contractors
          </h2>

          <img
            src="https://gbdevpress.enerbank.com/wp-content/uploads/2021/12/loanOptions-graphic.png"
            alt="loan options"
          />
        </div>
      </section>
    </Layout>
  )
}

export default RegionsPage
